<template>
    <div>
        <forms-inputs-input-label :for="$attrs.for" :title="$t(title)"/>

        <div class="relative">
            <input :id="$attrs.for" :name="$attrs.for" :required="required" :type="show_password ? 'text': 'password'"
                   class="input mt-1" @change="$emit('set', $event)">

            <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <icon :class="show_password ? 'text-primary-500' : 'text-gray-400'" class="hover:cursor-pointer"
                      name="ic:outline-remove-red-eye"
                      size="14"
                      @click="show_password = !show_password"/>
            </div>
        </div>
    </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: 'form.password',
    },

    required: {
        type: Boolean,
        default: false,
    },
})

const show_password = ref(false)
</script>
